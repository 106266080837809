import { React, useState } from 'react'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Button from 'react-bootstrap/Button';


export default function ShowDefault(props) {
    const [locationError, setLocationError] = useState(false);
    const [val, setVal] = useState(props.pInfo);
    const handleLocationError = () => {
        if (val.location.length === 0) {
            setLocationError(true);
            setVal({ ...val, locationValid: false });
        }
    }

    const handleLocationNr = (e) => {
        setLocationError(false);
        setVal({ ...val, locationValid: true, location: e.target.value });
        if (e.target.value.length == 0)
            setVal({ ...val, locationValid: false, location: e.target.value });
        else setVal({ ...val, locationValid: true, location: e.target.value });

    }

    const handlePage = () => {
        switch (val.page) {
            case 1:
                if (val.locationValid) {
                    val.vehicleDefault = true;
                    val.showVehicle = true;
                    val.page = val.page + 1;
                    props.fct("item2", val);
                }
                break;
            case 2:
                if (val.locationValid && val.vehicleNr) {
                    val.bar = 2;
                    props.fct("item2", val);
                }
                break;
        }

    }

    const handlePage1 = () => {
        props.fct("item1", val);
    }

    return (
        <>
            <h6 style={{ color: "#4d4d4d", fontWeight: "400", marginTop: "15px", fontSize: "1.15rem", marginBottom: "20px" }}>Fahrzeugdetails eingeben</h6>

            {/* aici */}
        

            <div className="form-group">
                <h3>Kennzeichen</h3>
                <div className={`input-group ${locationError ? "form-group-error" : ""} ${val.locationValid ? "form-group-valid" : ""} `}  >
                <svg className="icon-location" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" fill="#9B9B9B" d="M16.9583 8.64825L17.3459 10H6.80326L7.35456 8.39777C8.05408 6.36478 9.967 5 12.117 5C14.3639 5 16.339 6.48838 16.9583 8.64825ZM4.68817 10L5.46338 7.74704C6.44069 4.90674 9.11323 3 12.117 3C15.2561 3 18.0156 5.07942 18.8809 8.09699L19.4265 10H19.607C21.1165 10 22.3909 11.1216 22.5827 12.6188L22.9833 15.7459C23.1364 16.9416 22.2049 18 20.9995 18H19.75C19.75 19.6569 18.4069 21 16.75 21C15.0931 21 13.75 19.6569 13.75 18H10.25C10.25 19.6569 8.90685 21 7.25 21C5.59315 21 4.25 19.6569 4.25 18H3.00068C1.79524 18 0.863729 16.9416 1.01688 15.7459L1.41743 12.6188C1.60921 11.1216 2.88362 10 4.39311 10H4.68817ZM4.39311 12C3.88995 12 3.46515 12.3739 3.40122 12.8729L3.00068 16H5.01389C5.56321 15.3862 6.3615 15 7.25 15C8.1385 15 8.93679 15.3863 9.48611 16L14.5139 16C15.0632 15.3863 15.8615 15 16.75 15C17.6385 15 18.4368 15.3862 18.9861 16H20.9995L20.5989 12.8729C20.535 12.3739 20.1102 12 19.607 12H17.9194H6.11508H4.39311ZM8.25 18C8.25 18.5523 7.80228 19 7.25 19C6.69772 19 6.25 18.5523 6.25 18C6.25 17.4477 6.69772 17 7.25 17C7.80228 17 8.25 17.4477 8.25 18ZM16.75 19C17.3023 19 17.75 18.5523 17.75 18C17.75 17.4477 17.3023 17 16.75 17C16.1977 17 15.75 17.4477 15.75 18C15.75 18.5523 16.1977 19 16.75 19Z"></path><path d="M11 4V11" stroke="#9B9B9B" strokeWidth="2"></path></svg>
                   
                    <input type="text" onMouseOut={handleLocationError} onChange={handleLocationNr} value={val.location} className="form-control vehicle-input" placeholder="Kennzeichen" />
                </div>
                {locationError ?
                    <div className="error-location">
                        <p className="error-location-text">Kennzeichen ist erforderlich</p>
                    </div> : <></>
                }
            </div>
            <Button variant="second" size="medium" onClick={handlePage} className={`confirm-button ${(val.vehicleDefault === true && val.showVehicle === true) ? "confirm-button-enabled" : ""} ${!val.locationValid ? "confirm-button-enabled" : ""}`}>PARKEN</Button>
        </>
    )
}