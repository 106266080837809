import '../App.css';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import 'react-calendar/dist/Calendar.css';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import "./Park.css"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Calendar from 'react-calendar'
import Navbar from "../Components/NavBar"
import ShowDefault from '../Components/ShowDefault';
import Show1 from '../Components/Show1';
import Show2 from '../Components/Show2';
import Show3 from '../Components/Show3';
import Show4 from '../Components/Show4';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function Park(props) {
  const [show, setShow] = useState(true);
  const [title, setTitle] = useState("Parkingpay");
  const handleClose = () => setShow(false);
  const [mode, setMode] = useState(["item0"]);
  const [pInfo, setPInfo] = useState({ bar: 1, duration: "", page: 1, vehicles: "", location: "", vehicleDefault: false, showVehicle: false, locationValid: false, vehicleNr: "A vehicle is required", nrValid: false });

  const handleMode = (m, val) => {
    setMode(m);
    setPInfo(val);
  }

  const handleBack = () => {
    if (mode === "item1") {setMode("item0"); setPInfo({...pInfo, bar: 1})}
    if (mode === "item2") {setMode("item0"); setPInfo({...pInfo, bar: 1})}
    if (mode === "item3") {setMode("item2");setPInfo({...pInfo, bar: 2})}
  }

  function DisplayBody(props) {
    const handleM = (m, val) => {
      props.hm(m, val);
    }
    switch (mode) {
      case 'item0':
        setTitle("Parkingpay");
        return (<ShowDefault fct={handleM} pInfo={props.pInfo} />)
      case 'item1':
        setTitle("Parkingpay");
        return (<Show2 fct={handleM} pInfo={props.pInfo} />)
      case 'item2':
        setTitle("Parkingpay");
        return (<Show2 fct={handleM} pInfo={props.pInfo} />)
      case 'item3':
        setTitle("Parkingpay");
        return (<Show3 fct={handleM} pInfo={props.pInfo} />)
      default:
        return (<ShowDefault fct={handleM} pInfo={props.pInfo} />)
    }
  }



  return (
    <>
      <Modal
        show={show}
        scrollable={true}
        onHide={handleClose}
        dialogClassName="modal-90w"
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={true}
      >
        <Navbar />
        <div className='head'>
          <Modal.Header style={{ padding: "12px 16px" }}>
            <span onClick={handleBack}><ArrowBackIosIcon style={{ color: "white" }}></ArrowBackIosIcon></span>
            <Modal.Title className="title">{title}</Modal.Title>
          </Modal.Header>
        </div>
        {pInfo.bar === 1 ?
          <div class="sc-iEXKAA dGmZcK"><div width="33.333333333333336" class="sc-EgOXT gzAQU"></div></div>
          : pInfo.bar === 2 ?
          <div class="sc-iEXKAA dGmZcK"><div width="66.66666666666667" class="sc-EgOXT LWxtv"></div></div>
          : <div class="sc-iEXKAA dGmZcK"><div width="100" class="sc-EgOXT euiWPR"></div></div>
        }
        <Modal.Body style={{ padding: "20px", backgroundColor: "#f5f5f5" }}>
          <DisplayBody hm={handleMode} mode={mode} pInfo={pInfo} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Park;