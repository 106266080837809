import { React, useState, useRef } from 'react'
import Button from 'react-bootstrap/Button';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';





export default function Show2(props) {
    const [timeErr, setTimeErr] = useState(false);
    let [val, setVal] = useState(props.pInfo);
    const [time, setTime] = useState("");
    const [timeValid, setTimeValid] = useState(false);
    const optionValue = useRef();
    const handleTimeError = () => {
        if (time.length === 0) {
            setTimeErr(true);
            setTimeValid(false);
        }
    }
    const handleTime = (e) => {
        setTimeErr(false);
        setTimeValid(true);
        const time2 = e.target.value + " " + optionValue.current.value;
        console.log(time2);
        setTime(e.target.value);
        setVal({ ...val, duration: time2 });
        if (e.target.value.length == 0) {
            setTimeErr(true);
            setTimeValid(false);

        }
    }

    const handlePage = () => {
            const time2 = time + " " + optionValue.current.value;
            val.bar = 3;
            val = {...val, duration: time2};
            props.fct("item3", val);
    }

    const handleCancel = () => {
        props.fct("item0", props.pInfo);
    }

    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px", marginBottom:"30px" }}>
                <LocationOnOutlinedIcon className="icon-location" style={{ width: "1.2em", height: "1.2em" }} />
                <h6 style={{ textAlign: "center", color: "#4d4d4d", fontWeight: "600", margin: "0", fontSize: "1.15rem", paddingLeft: "3px" }}>{val.location}</h6>
            </div>
            {/* aici */}
            <p style = {{fontSize:"1.2rem"}}>Wie lange möchtest du parken?</p>
            <div style={{ display: "flex", gap: "30px" }}>
                <div className="form-group" style={{ flex: "1" }}>
                    <h3>Parkdauer</h3>
                    <div className={`input-group ${timeErr ? "form-group-error" : ""} ${timeValid ? "form-group-valid" : ""} `}  >
                        <svg className="icon-location" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M2 8C2 6.89543 2.89543 6 4 6H20C21.1046 6 22 6.89543 22 8V16C22 17.1046 21.1046 18 20 18H4C2.89543 18 2 17.1046 2 16V8Z" stroke="#9B9B9B" strokeWidth="2" fill="none"></path><circle cx="5.5" cy="12" r="1" fill="#9B9B9B"></circle><circle cx="18.5" cy="12" r="1" fill="#9B9B9B"></circle><path fillRule="evenodd" clipRule="evenodd" d="M7.5 10C7.5 9.44772 7.94772 9 8.5 9H9.5C10.0523 9 10.5 9.44772 10.5 10C10.5 10.0002 10.5 10.0005 10.5 10.0007L10.5 13C11.0523 13 11.5 13.4477 11.5 14C11.5 14.5523 11.0523 15 10.5 15H8.5C7.94772 15 7.5 14.5523 7.5 14C7.5 13.4477 7.94772 13 8.5 13V11C7.94772 11 7.5 10.5523 7.5 10Z" fill="#9B9B9B"></path><path fillRule="evenodd" clipRule="evenodd" d="M13.5 11L14.5 11L12.7077 13.3897C12.6304 13.49 12.5716 13.6054 12.5367 13.7306C12.507 13.8367 12.4955 13.9461 12.5014 14.0543C12.5087 14.1897 12.5429 14.3179 12.5987 14.4337C12.6636 14.5689 12.7599 14.6913 12.886 14.7893C12.9363 14.8285 12.9904 14.863 13.0478 14.8921C13.1952 14.9671 13.3555 15.0023 13.5143 15H15.5C16.0523 15 16.5 14.5522 16.5 14C16.5 13.4477 16.0523 13 15.5 13L16.1 12.2C17.0888 10.8815 16.148 8.99996 14.4999 9L13.5 9.00002C12.9477 9.00004 12.5 9.44776 12.5 10C12.5 10.5523 12.9477 11 13.5 11Z" fill="#9B9B9B"></path><path d="M10 7H14" stroke="#9B9B9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"></path></svg>
                        <input type="number" onMouseOut={handleTimeError} onChange={handleTime} value={time} className="form-control vehicle-input" placeholder="" />
                    </div>
                    {timeErr ?
                        <div className="error-location">
                            <p className="error-location-text">Parkdauer ist erforderlich</p>
                        </div> : <></>
                    }
                </div>

                <div className="form-group" style={{ flex: "1" }}>
                    <h3>Zeitangabe</h3>
                    <div className="input-group">
                        <svg className="icon-location" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" fill="#9B9B9B" d="M16.9583 8.64825L17.3459 10H6.80326L7.35456 8.39777C8.05408 6.36478 9.967 5 12.117 5C14.3639 5 16.339 6.48838 16.9583 8.64825ZM4.68817 10L5.46338 7.74704C6.44069 4.90674 9.11323 3 12.117 3C15.2561 3 18.0156 5.07942 18.8809 8.09699L19.4265 10H19.607C21.1165 10 22.3909 11.1216 22.5827 12.6188L22.9833 15.7459C23.1364 16.9416 22.2049 18 20.9995 18H19.75C19.75 19.6569 18.4069 21 16.75 21C15.0931 21 13.75 19.6569 13.75 18H10.25C10.25 19.6569 8.90685 21 7.25 21C5.59315 21 4.25 19.6569 4.25 18H3.00068C1.79524 18 0.863729 16.9416 1.01688 15.7459L1.41743 12.6188C1.60921 11.1216 2.88362 10 4.39311 10H4.68817ZM4.39311 12C3.88995 12 3.46515 12.3739 3.40122 12.8729L3.00068 16H5.01389C5.56321 15.3862 6.3615 15 7.25 15C8.1385 15 8.93679 15.3863 9.48611 16L14.5139 16C15.0632 15.3863 15.8615 15 16.75 15C17.6385 15 18.4368 15.3862 18.9861 16H20.9995L20.5989 12.8729C20.535 12.3739 20.1102 12 19.607 12H17.9194H6.11508H4.39311ZM8.25 18C8.25 18.5523 7.80228 19 7.25 19C6.69772 19 6.25 18.5523 6.25 18C6.25 17.4477 6.69772 17 7.25 17C7.80228 17 8.25 17.4477 8.25 18ZM16.75 19C17.3023 19 17.75 18.5523 17.75 18C17.75 17.4477 17.3023 17 16.75 17C16.1977 17 15.75 17.4477 15.75 18C15.75 18.5523 16.1977 19 16.75 19Z"></path><path d="M11 4V11" stroke="#9B9B9B" strokeWidth="2"></path></svg>
                        <select name="cars" id = "" className="form-control vehicle-select" ref = {optionValue}>
                            <optgroup style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "0.75em" }} label="Zeitangabe">
                               
                                <option value="hours">Std.</option>
                                <option value="days">Min.</option>
                            </optgroup>
                        </select>
                        <svg className="icon-location" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg>
                    </div>

                </div>
            </div>
            <h6>Höchstparkdauer</h6>
            <p style = {{color: "#9B9B9B"}}>Bis zu 24 Stunden</p>
            <Button style={{ marginBottom: "20px" }} variant="second" size="medium" onClick={handlePage} className={`confirm-button  ${!val.nrValid ? "confirm-button-enabled" : ""}`}>PARKEN</Button>
        </>
    )
}
